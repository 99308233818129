<template>
  <div class="container settings">
    <div class="row mb-3">
      <div class="col">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row mb-4">
                <div class="col-md-6 mb-3 mb-md-0">
                  <label for>First Name</label>
                  <input
                    type="text"
                    v-model="user.first_name"
                    class="form-control"
                  />
                </div>
                <div class="col-md-6 mb-3 mb-md-0">
                  <label for>Last Name</label>
                  <input
                    type="text"
                    v-model="user.last_name"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>Email Address</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary" type="button" @click="$router.push({name: 'SettingsChangeEmail'})">Change</button>
                    </div>
                    <input
                      type="text"
                      :value="user.email"
                      class="form-control disabled"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>Phone</label>
                  <e164-phone-number-input
                    v-model="user.phone"
                    default-country-code="GB"
                    :preferred-countries="[
                      'GB',
                      'MT',
                      'GR',
                      'ES',
                      'FR',
                      'BE',
                      'DE',
                    ]"
                    :clearable="true"
                  ></e164-phone-number-input>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>Mobile</label>
                  <e164-phone-number-input
                    v-model="user.mobile"
                    default-country-code="GB"
                    :preferred-countries="[
                      'GB',
                      'MT',
                      'GR',
                      'ES',
                      'FR',
                      'BE',
                      'DE',
                    ]"
                    :clearable="true"
                  ></e164-phone-number-input>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>Date of Birth</label>
                  <input type="date" v-model="user.dob" class="form-control" />
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>Gender</label>
                  <select v-model="user.gender" class="form-control">
                    <option value="" :selected="user.title == null">
                      Not Selected
                    </option>
                    <option value="female" :selected="user.gender == 'female'">
                      Female
                    </option>
                    <option value="male" :selected="user.gender == 'male'">
                      Male
                    </option>
                    <option value="non-binary" :selected="user.gender == 'non-binary'">
                      Non-binary
                    </option>
                  </select>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>Personal Title</label>
                  <select id="title" v-model="user.title" class="form-control">
                    <option value="" :selected="user.title == null">
                      Not Selected
                    </option>
                    <option value="mr" :selected="user.title == 'mr'">
                      Mr
                    </option>
                    <option value="mrs" :selected="user.title == 'mrs'">
                      Mrs
                    </option>
                    <option value="miss" :selected="user.title == 'miss'">
                      Miss
                    </option>
                    <option value="ms" :selected="user.title == 'ms'">
                      Ms
                    </option>
                    <option value="mx" :selected="user.title == 'mx'">
                      Mx
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4 mb-3 mb-md-0">
                  <label for>NHS Number</label>
                  <input type="text" v-model="user.nhs_number" class="form-control" maxlength="12" />
                </div>
              </div>

              <div class="row" v-if="user.require_tfa">
                <div class="col my-auto">
                  <p class="alert alert-info">
                    <i class="fad fa-info-circle mr-1"></i>
                    Enabling 2FA will require you to confirm your identity via
                    an SMS code sent to your mobile phone number provided above
                    before you are able to access the system.
                  </p>
                  <div class="custom-control custom-switch mb-1">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="requireTfa"
                      v-model="user.require_tfa"
                      :checked="user.require_tfa"
                      :disabled="!user.mobile"
                    />
                    <label class="custom-control-label" for="requireTfa"
                      >Enable Two-Factor Authentication</label
                    >
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-auto ml-auto">
                  <button type="submit" class="btn btn-success mt-3">
                    Update My Settings
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import E164PhoneNumberInput from "../../components/input/PhoneNumberInput";
export default {
  components: { E164PhoneNumberInput },
  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.user;
    },
    dob: {
      get() {
        return moment(this.user.dob).format("YYYY-MM-DD");
      },
      set(val) {
        this.user.dob = val;
      },
    },
  },
  methods: {
    onSubmit() {
      var send = true;
      if (this.user.require_tfa) {
        send = this.user.mobile;
        if (!send) {
          alert("Cannot remove mobile while 2FA is active.");
        }
      }

      if (send) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/client/update-user-settings",
            this.user
          )
          .then(({ data }) => {
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
          });
      }
    },
  },
  watch: {},
};
</script>

<style>
</style>
